import "./UsersPage.css"
import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import EditUserModal from "../../components/common/Modals/EditUserModal";
import DeleteUserModal from "../../components/common/Modals/DeleteUserModal";
import { AppDispatch } from "../../redux/store";
import { fetchUsers } from "../../redux/actions/users.actions";
import { useSearchParams } from "react-router-dom";
import UsersContainer from "./components/UsersContainer";

const UsersPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  // Data
  const users = useSelector((state: RootState) => state.users.data);
  const searchParams = useSearchParams()[0];

  // Checked items
  const [checked, setChecked] = useState<any[]>([]);

  //Modal Handling
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userToEdit, setUser] = useState<any|null>(null);

  // Dispatch UseEffects
  useEffect(() => {
    dispatch(fetchUsers(searchParams.toString()));
  }, [dispatch, searchParams]);

  useEffect(() => {
    setChecked(users?.map((u)=>{return {id:u?.id, is_checked:false}}))
  }, [users]);

  return (
    <>
      <EditUserModal isOpen={editOpen} setOpen={setEditOpen} id={userToEdit?.id}/>
      <DeleteUserModal isOpen={deleteOpen} setOpen={setDeleteOpen} user={userToEdit}/>
      <Header/>
      <UsersContainer 
        checked={checked}
        setChecked={setChecked}
        setEditOpen={setEditOpen}
        setDeleteOpen={setDeleteOpen}
        setUser={setUser}
      />
    </>
  );
};

export default UsersPage;
