import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteSpaApi, deregisterSpaUserApi, editNicknameApi, getSpaById, getUserSpas, registerSpaUserApi } from '../../services/api/spas.api';
import RootState from "../states/root.state";

export const fetchSpa = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'spa',
    async (id, {rejectWithValue, getState}) => {
        try {

            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getSpaById(token, id);
            return response.data;
            
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred while requesting logs.');
            }
        }
    }
);

export const editNickname = createAsyncThunk<any, {spa:any}, { rejectValue: string; state: RootState}>(
    'spa/update',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
            const response = await editNicknameApi(token,updateInfo.spa).then(()=>{return getSpaById(token, updateInfo.spa.id)});
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during editing.');
            }
        }
    }
);

export const deleteSpa = createAsyncThunk<any, {guid:string, params:string}, { rejectValue: string; state: RootState}>(
    'spa/delete',
    async (spaInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await deleteSpaApi(token, spaInfo.guid).then(()=>{return getUserSpas(token, spaInfo.params)});

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during deletion.');
            }
        }
    } 

);

export const deregisterSpaUser = createAsyncThunk<any, {guid:string, user_id:string}, { rejectValue: string; state: RootState}>(
    'spa/deregister',
    async (spaInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await deregisterSpaUserApi(token, spaInfo).then(()=>{return getSpaById(token, spaInfo.guid)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during deregistration.');
            }
        }
    } 
);

export const registerSpaUser = createAsyncThunk<any, {guid:string, user_id:string}, { rejectValue: string; state: RootState}>(
    'spa/register-to-user',
    async (spaInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await registerSpaUserApi(token, spaInfo).then(()=>{return getSpaById(token, spaInfo.guid)});;
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during deregistration.');
            }
        }
    } 
);