import React, {useEffect, useState} from "react";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow,
    TableSortLabel,
    Box,
    CircularProgress,
    Pagination,
  } from "@mui/material";
  import { visuallyHidden } from '@mui/utils';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { fetchSpaHistory } from "../../../redux/actions/spaHistory.actions";

interface ReportsTableProps {
    spa_guid: string;
    category: string;
    selected: any[];
    startDate: string;
    endDate: string;
}

type Order = 'asc' | 'desc';

const ReportsTable: React.FC<ReportsTableProps> = ({selected, startDate, endDate, spa_guid, category}) => {
    const dispatch: AppDispatch = useDispatch();
    const {data: history, loading} = useSelector((state: RootState) => state.spaHistory);
    // Table Sort
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState('');
    const [sortedRows, setSorted] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 25;

    useEffect(() => {
        setPage(1);
        let keys = [...selected];
        let type = 'setting';
        switch(category){
            case 'errors':
                type = 'error';
                break;

            case 'statuses':
                type = 'status';
                break;

            case 'connections':
                type = 'connection';
                break;
        }
        dispatch(fetchSpaHistory({guid:spa_guid, date_from:new Date(startDate).getTime(), date_to:new Date(endDate).getTime(), keys:keys, type:type}));
    },[dispatch, spa_guid, selected, startDate, endDate, category]);

    useEffect(() => {
        setSorted([...history].sort((a:any, b:any)=>{
            let timeA = new Date(a?.timestamp);
            let timeB = new Date(b?.timestamp);
            return timeA===timeB ? 0 : timeA>timeB ? -1 : 1;
        })); //sort rows by time

        let newArray: any[] = [];
        history.forEach(obj => {
            newArray = newArray.concat(Object.keys(obj));
        });
        let newSet = new Set(newArray);
        newArray = Array.from(newSet).filter((header:any)=>header!=='spa_guid' && header!=='timestamp').sort(); //sort headers alphabetically

        if (category==='errors'||category==='statuses'){ //sort error codes properly
            newArray.sort((a:any,b:any)=>{
                var numA = Number(a.match(/\d/g).join(""));
                var numB = Number(b.match(/\d/g).join(""));
                return numA===numB ? 0 : numA>numB ? 1 : -1;
            })
        }
        newArray.push('timestamp');
        setHeaders(newArray);
    },[history,category]);

    const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header: string,
    ) => {
    const isAsc = orderBy === header && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header);

    handleSort();
    };
    
    const handleSort = () => {
        let newArray = [...history].sort((a:any, b:any)=>{
            let isAsc = order ==='asc';
            let valA = a[orderBy];
            let valB = b[orderBy];
            if (!valA || !valB) {
                return 0;
            }

            switch (orderBy){
                case 'timestamp':
                    let timeA = new Date(valA);
                    let timeB = new Date(valB);
                    return timeA===timeB ? 0 : isAsc ? timeA>timeB ? -1 : 1 : timeA<timeB ? -1 : 1;
    
                default:
                    return valA===valB ? 0 : isAsc ? valA>valB ? -1 : 1 : valA<valB ? -1 : 1;
            }
        })
        setSorted(newArray);   
    }
    
  return (
    <>
    <div className="table">
        {loading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        sortedRows.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                    {headers.map((header:any)=>
                    header!=='spa_guid' &&
                    <TableCell key={header} sx={{color:'var(--title-gray)',background:`var(--as-white)`, fontSize:'1rem'}}>
                        <TableSortLabel
                            active={orderBy === header}
                            direction={orderBy === header ? order : 'asc'}
                            onClick={(e)=>handleRequestSort(e,header)}
                        >
                            {header}
                            {orderBy === header ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    )}

                </TableRow>
              </TableHead>

              <TableBody>
                {(sortedRows.length > rowsPerPage
                    ? sortedRows.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage)
                    : sortedRows
                ).map((row:any,i)=>
                    <TableRow key={i}>
                        {headers.map((key:any)=>
                            key!=='spa_guid' &&
                            <TableCell key={key}>
                                {key==='timestamp' ? 
                                    new Date(row[key]).toUTCString() 
                                    : 
                                typeof row[key] === 'boolean' ?
                                    row[key] ? 'ON' : 'OFF'
                                    :
                                    row[key]
                                }
                            </TableCell>
                        )}
                    </TableRow>
                )}
              </TableBody>
            </Table>
            ):(
            <div className="empty-table">No Reports Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
              <Pagination 
                count={Math.ceil(sortedRows.length/rowsPerPage)} 
                variant="outlined" 
                onChange={(e,page)=>setPage(page)}
                page={page}
                showLastButton 
                showFirstButton 
                disabled={sortedRows.length<=25}
                />
              <div className="total-count">Showing {sortedRows.length > rowsPerPage ? sortedRows.slice((page-1) * rowsPerPage, (page-1) * rowsPerPage + rowsPerPage).length : sortedRows.length}/{sortedRows.length} Reports</div>
            </div>
        </div>
    </>
  );
};

export default ReportsTable;
