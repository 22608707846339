import axios from 'axios';
import { BASE_URL } from './apiconfig';
import User from '../../redux/models/user.model';

export const getRoles = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/roles?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull roles list. ", error);
    throw error;
  }
}

export const getRole = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/roles/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to fetch role. ", error);
    throw error;
  }
}

export const putRole = async (token: string | null, updateInfo:{id:string, name:string, description:string}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/admin/roles/${updateInfo.id}`,{
      name: updateInfo.name,
      description: updateInfo.description
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to update role. ", error);
    throw error;
  }
}

export const assignRoleApi = async (token: string | null, roleInfo: {role_id:string, users:User[]}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/admin/roles/assign`, {
      role_id: roleInfo.role_id,
      users: roleInfo.users
    },{
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to assign role to users. ", error);
    throw error;
  }
}

export const removeRoleApi = async (token: string | null, roleInfo: {role_id:string, users:User[]}) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/admin/roles/remove`, {
      role_id: roleInfo.role_id,
      users: roleInfo.users
    },{
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to remove role from users. ", error);
    throw error;
  }
}

export const getRoleUsers = async (token: string | null, id:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/admin/roles/${id}/users`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to fetch role's users. ", error);
    throw error;
  }
}