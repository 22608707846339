import React, { useEffect, useState } from "react";

interface ActionButtonProps {
  onClick: () => void;
  initialStatus: number;
  title: string;
}
export const PumpButton: React.FC<ActionButtonProps> = ({
  onClick,
  initialStatus,
  title = "Pump",
}) => {
  const [status, setStatus] = useState(initialStatus);

  // Set status if the initial status changed.
  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const handleClick = () => {
    onClick();
  };

  return (
    <div className="button-wrapper">
      <svg
        className="pump-icon"
        width="40"
        height="40"
        viewBox="0 0 22 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClick}
      >
        <path
          d="M15.2532 14.9568L21.0001 14.9424M15.2532 5.92596L21.0001 2.4201M21.0001 27.2717L15.2532 23.711"
          stroke={status > 15 ? "var(--as-black)" : "var(--icon-disabled)"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.38891 23.9878C8.03703 26.8015 6.88368 29.5316 4.5614 28.8563C1.65854 28.0122 0.602959 22.9474 1.13075 11.1296C1.55298 1.67533 2.18637 1 4.5614 1C6.93642 1 7.56792 2.64203 8.38891 5.105M6.74693 10.8519L12.4939 7.56792M6.74693 14.9424L12.4939 14.9568M12.4939 22.3457L6.74693 18.6513"
          stroke={status > 0 ? "var(--as-black)" : "var(--icon-disabled)"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className="pump-label">{title}</div>
    </div>
  );
};

export default PumpButton;
