import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField, Button, FormControl, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { addCommand } from "../../../redux/actions/commands.actions";

interface ModalProps {
    open: boolean, 
    setOpen: any,
    params: string
}

const AddCommandModal: React.FC<ModalProps> = ({open, setOpen,params}) => {
    const handleClose = () => {
      handleReset();
      setOpen(false);
    };

    const handleReset = () => {
      setSubmitted(false);
      setTitle('');
      setBody('');
      setHelperText('');
    };

    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const { error, loading } = useSelector((state: RootState) => state.commands);
    
    const [submitted, setSubmitted] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [helperText, setHelperText] = useState('');
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setSubmitted(true);
      setHelperText('');
      if(isJsonString(body)) {
        await dispatch(addCommand({title:title, body:JSON.parse(body), params:params}));
      } else {
        setHelperText('Please input a valid JSON object.')
      }
    };

    function isJsonString(body:string) {
      try {
          JSON.parse(body);
      } catch (e) {
          return false;
      }
      return true;
  }

if (!submitted || loading || helperText!==""){
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Create New Command
          </div>

            <div className={styles.body}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="title"
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={loading}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="body"
                    label="Body"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    multiline
                    required
                    disabled={loading}
                    error={helperText!==''}
                    helperText={helperText}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading || !accessToken}
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Create Command"
                    )} 
                </Button>
              </form>
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (error){
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{`Command successfully added.`}</div>
          <div className="modal-btn-group">
              <button className="as-text" onClick={handleClose}> Close </button>
              <button className="as-text" onClick={handleReset}> Add Another </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default AddCommandModal;
