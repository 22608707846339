import styles from "../../../assets/scss/Modal.module.scss";
import "../../../components/common/Modals/Modals.css";
import React, {useState} from "react";
import {Modal} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import CircularProgress from "@mui/material/CircularProgress";
import CommandSelect from "../../../components/common/UserSelect/CommandSelect";
import { addSpaCommands } from "../../../redux/actions/commands.actions";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    spas: any[],
    params?: string,
}

const AddSpaCommandsModal: React.FC<ModalProps> = ({isOpen, setOpen, spas}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedCommands, setSelectedCommands] = useState<any>(null);
    const [submitted, setSubmitted] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const { loading, error } = useSelector((state: RootState)=>state.spa);
    const [results, setResults] = useState<any[]>([]);

    const handleClose = () => {
        setSubmitted(false);
        setSelectedCommands(null);
        setInProgress(false);
        setResults([]);
        setOpen(false);
    };

    const handleSubmit = async () => {
        setSubmitted(true);
        setInProgress(true);

        if((spas!==undefined && spas.length>0)&&(selectedCommands)){
            let newArray : any[] = [];
            spas.forEach(async (spa:any) => {
                const result = await dispatch(addSpaCommands({command_id:selectedCommands.id, spa_id: spa.id}));
                let message = "";
                switch (result.type){
                    case "commands/assign-to-spa/fulfilled":
                        message = "Success"
                        break;
    
                    case "commands/assign-to-spa/rejected":
                        message = error ? error : 'Rejected'
                        break;
                }
                newArray.push({spa:spa, message:message});
                setResults(newArray);;
            })
        };
        setInProgress(false);
}

if(!submitted) {
return (
    <div>
        <Modal
        open={isOpen}
        onClose={handleClose}
        >
        <div className={styles.modal}>
            <div className={styles.header}>{spas.length>1 ? `Command ${spas.length} Spas`:`Command  ${spas[0]?.nick_name} Spa`}</div>
            <div className={styles.body}>
                <p>Select a command below: </p>
                <CommandSelect setSelectedCommands={setSelectedCommands}/>
            </div>
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>
                <button className="as-button" onClick={handleSubmit} disabled={selectedCommands===null}> Command </button>
            </div>
        </div>
        </Modal>
    </div>
    );
} else if (loading || inProgress) {
    return (
        <div>
            <Modal
            open={isOpen}
            onClose={handleClose}
            >
            <div className={styles.modal}>
                <div className={styles.header}>Adding Commands...</div>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            </div>
            </Modal>
        </div>
    );
} else {
    return (
        <div>
            <Modal
            open={isOpen}
            onClose={handleClose}
            >
            <div className={styles.modal}>
                <div className={styles.header}>Commands Sent</div>
                <div className={styles.body}>
                    <p>Click on Spa's GUID to view their details page</p>
                    <div className="spa-select">
                        {results.map((res:any)=>(
                            <div key={res?.spa?.id} className="update-results">
                                <span className="clickable-icon" onClick={()=>navigate(`/spa/${res?.spa?.id}`)}>
                                {res?.spa?.nick_name ? 
                                <span className="name-guid">
                                    {`${res?.spa?.nick_name} (`}<span className="partial-guid">{res?.spa?.id}</span>{`)`}
                                </span>
                                :
                                <div className="partial-guid">{res?.spa?.id}</div>
                                } 
                                </span>
                                <span >{res?.message}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-btn-group">
                    <button className="as-text" onClick={handleClose}> Close </button>
                </div>
            </div>
            </Modal>
        </div>
        );
}

};

export default AddSpaCommandsModal;
