import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SpasTable from "../../../components/common/Tables/SpasTable";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { useSearchParams } from "react-router-dom";
import { SpaErrorLabels } from "../../../mqttData/errors/spaErrorLabels.enums";
import { ArcticLabels } from "../../SpaErrors/components/arcticLabels.enums";
import { formatErrorCode } from "../../SpaDetails/formatCodes";

interface ContainerProps {
    checked: any[];
    setChecked: any;
    setConfirmUpdate: any;
    setConfirmLogs: any;
    setDeleteOpen: any;
    setEditOpen:any;
    setAddCommandsOpen: any;
    setSpaToModify:any;
}


const SpasContainer: React.FC<ContainerProps> = ({
    checked,
    setChecked,
    setConfirmUpdate,
    setConfirmLogs,
    setDeleteOpen,
    setEditOpen,
    setAddCommandsOpen,
    setSpaToModify,
}) => {
  const {data: currentUser} = useSelector((state: RootState) => state.user);
  const [isAdminDev, setIsAdminDev] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (currentUser){
      setIsAdminDev(currentUser.roles?.some((role: any) =>["admin","developer"].includes(role.name)) || false)
    }
  }, [currentUser]);

  // Search and Filter
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [ip, setIp] = useState("");
  const [email, setEmail] = useState("");
  const [online, setOnline] = useState(searchParams.get('online')!==null);
  const [registered, setRegistered] = useState(searchParams.get('registered')!==null);
  const [hasErrors, setHasErrors] = useState(searchParams.get('has_errors')!==null);
  const [error, setError] = useState("");

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    name==="" ? searchParams.delete('nick_name') : searchParams.set('nick_name', name);
    serial==="" ? searchParams.delete('arctic_serial_number') : searchParams.set('arctic_serial_number', serial);
    ip==="" ? searchParams.delete('ip_address') : searchParams.set('ip_address', ip);
    email==="" ? searchParams.delete('user_email') : searchParams.set('user_email', email);
    error==="" ? searchParams.delete('error') : searchParams.set('error', error);

    !online ? searchParams.delete('online') : searchParams.set('online', 'true');
    !registered ? searchParams.delete('registered') : searchParams.set('registered', 'true');
    !hasErrors ? searchParams.delete('has_errors') : searchParams.set('has_errors', 'true');
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    setName("");
    searchParams.delete('nick_name');

    setSerial("");
    searchParams.delete('arctic_serial_number');

    setIp("");
    searchParams.delete('ip_address');

    setEmail("");
    searchParams.delete('user_email');

    setOnline(false);
    searchParams.delete('online');

    setRegistered(false);
    searchParams.delete('registered');

    setHasErrors(false);
    searchParams.delete('has_errors');

    setError("");
    searchParams.delete('error');

    setSearchParams(searchParams);
  }

  return (
        <div className="list">
          <div className="filters">
            <div className="search">
                <TextField
                  id="name-search"
                  label="Nickname"
                  type="search"
                  fullWidth={true}
                  value={name??""}
                  onChange={(e)=>setName(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"
                />

                <TextField
                  id="serial-search"
                  label="Serial Number"
                  type="search"
                  fullWidth={true}
                  value={serial??""}
                  onChange={(e)=>setSerial(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"  
                />

                <TextField
                  id="ip-search"
                  label="IP Address"
                  type="search"
                  fullWidth={true}
                  value={ip??""}
                  onChange={(e)=>setIp(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"
                />

                <TextField
                  id="email-search"
                  label="Email Address"
                  type="search"
                  fullWidth={true}
                  value={email??""}
                  onChange={(e)=>setEmail(e.target.value)}
                  onKeyDown={(e)=>(e.code==="Enter" ? handleFilter() : null)}
                  size="small"
                />
                <FormControl fullWidth size="small" disabled={!hasErrors}>
                  <InputLabel>Errors</InputLabel>
                  <Select
                    label="error-selection"
                    defaultValue="" 
                    disabled={!hasErrors}
                    onChange={(e)=>setError(e.target.value)}
                    value={error}
                  >
                    {Object.values(SpaErrorLabels).map((code,i)=>
                      ArcticLabels[code]!=="" &&
                        <MenuItem key={i} value={code}>{formatErrorCode(code)} {ArcticLabels[code]}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
                <label><input type="checkbox" checked={hasErrors} onChange={(e)=>setHasErrors(e.target.checked)}></input>Has Errors</label>
              <hr></hr>
              <span>Yocto: </span>
              <div className="firmware-versions">
                <FormControl fullWidth size="small">
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="yocto-selection-min"
                    defaultValue="" 
                    
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="yocto-selection-max"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <span>LPC: </span>
              <div className="firmware-versions">
                <FormControl fullWidth size="small">
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="lpc-selection-min"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="lpc-selection-max"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <span>SpaBoy: </span>
              <div className="firmware-versions">
                <FormControl fullWidth size="small">
                  <InputLabel>Min</InputLabel>
                  <Select
                    label="spaboy-selection-min"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Max</InputLabel>
                  <Select
                    label="spaboy-selection-max"
                    defaultValue="" 
                     
                  >
                    <MenuItem value={1}>1.1</MenuItem>
                    <MenuItem value={2}>1.2</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <hr></hr>
              <div className="checkbox-filters">
                <label><input type="checkbox" checked={online} onChange={(e)=>setOnline(e.target.checked)}></input>Online</label>
                <label><input type="checkbox" checked={registered} onChange={(e)=>setRegistered(e.target.checked)}></input>Registered</label>
              </div>
              <hr></hr>
              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div>
          </div>

          <div className="table-section">
            <SpasTable 
              setChecked={setChecked} 
              checked={checked}
              setDeleteOpen={setDeleteOpen}
              setEditOpen={setEditOpen}
              setSpaToModify={setSpaToModify}
            />
            <div className="filter-btn-group spa-actions">
              <button 
                disabled={checked.length===0} 
                onClick={()=>setConfirmUpdate(true)}>
                  Update Firmware {checked.length>0&&`(${checked.length})`}
                </button>

              <button 
                disabled={checked.length===0} 
                onClick={()=>setConfirmLogs(true)}>
                  Request Logs {checked.length>0&&`(${checked.length})`}
                </button>

              {isAdminDev && <button 
                disabled={checked.length===0} 
                onClick={()=>setAddCommandsOpen(true)}> 
                Send API Commands {checked.length>0&&`(${checked.length})`}
              </button>}
            </div>
          </div>
        </div>
)
};

export default SpasContainer;