import axios from "axios";
import { BASE_URL } from "./apiconfig";
import {IUser} from "../../redux/models/user.model";

export const getUserData = async (token: string):Promise<IUser> => {
  try {
    const response = await axios.get(`${BASE_URL}/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (error) {
    console.log("Failed to get user data. ", error);
    throw error;
  }
};
export const loginUserApi = async (credentials: { Email: string; Password: string }) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, credentials, {
      withCredentials: true // This tells axios to send cookies along with the request
    });
    return response.data;
  } catch (error) {
    console.log("Failed to login user. ", error);
    throw error;
  }
};

export const registerUserApi = async (credentials: { first_name: string; last_name: string, username: string; email: string; password: string; password_confirmation: string }) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, credentials, {
      withCredentials: true // This tells axios to send cookies along with the request
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async (token: string | null) => {
  try {
    await axios.post(`${BASE_URL}/logout`, null, {
      withCredentials: true,
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log("Failed to logout. ", error);
    throw error;
  }
};

export const refreshAccessTokenApi = async () => {
  try {
      const response = await axios.post(`${BASE_URL}/auth/access`, null, {
          withCredentials: true, // Include cookies in the request
      });
      return response.data;
  } catch (error) {
      console.log("Failed to refresh access token. ", error);
      throw error;
  }
};

// Password API calls:
export const resetPasswordApi = async (credentials: { email: string; password: string, password_confirmation: string; token: string; }) => {
  try {
    const response = await axios.post(`${BASE_URL}/reset-password`, credentials, {
      withCredentials: true // This tells axios to send cookies along with the request
    });
    return response.data;
  } catch (error) {
    console.log("Failed to reset password. ", error);
    throw error;
  }
};

// Request reset link API calls:
export const requestResetLinkApi = async (credentials: { email: string;}) => {
  try {
    const response = await axios.post(`${BASE_URL}/forgot-password`, credentials, {
      withCredentials: true // This tells axios to send cookies along with the request
    });
    return response.data;
  } catch (error) {
    console.log("Failed to request reset link. ", error);
    throw error;
  }
};

// Edit profile API:
export const editProfileApi = async (token: string | null, credentials: IUser) => {
  try {
    const response = await axios.put(`${BASE_URL}/user/update`, credentials, {
      withCredentials: true, // This tells axios to send cookies along with the request
      headers: { Authorization: `Bearer ${token}` }
      // Get updated user 
    }).then( r => {
      try {
        return axios.get(`${BASE_URL}/user`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      } catch (error) {
          console.log("Failed to get user. ", error);
          throw error;
      }
    }
    );
    return response.data.data;
  } catch (error) {
    console.log("Failed to update user info. ", error);
    throw error;
  }
};

// Edit profile API:
export const changePasswordApi = async (token: string | null, credentials: {old_password: string, password: string, password_confirmation: string}) => {
  try {
    const response = await axios.put(`${BASE_URL}/user/update-password`, credentials, {
      withCredentials: true, // This tells axios to send cookies along with the request
      headers: { Authorization: `Bearer ${token}` }
    });
    return response;
  } catch (error) {
    console.log("Failed to change password. ", error);
    throw error;
  }
};