import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RootState from "../../../redux/states/root.state";
import {
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow,
  TableSortLabel,
  Box,
  Pagination
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { LinkOut } from "../../../assets/svg/svg";

type Order = 'asc' | 'desc';
const headers = [
  {id:'name', label:'Name'}, 
  {id:'dealer', label:'Dealer'}, 
  {id:'country_id', label:'Country'}, 
  {id:'region', label:'Region'}, 
  {id:'city', label:'City'},
  {id:'staff_count', label:'Staff'},
  {id:'url', label:'URL'},
  {id:'supplies', label:'Supplies'},
];

const DealershipsTable: React.FC = () => {
  const navigate = useNavigate();
  const {data:dealerships, loading:isLoading, meta} = useSelector((state: RootState) => state.dealerships);
  const [searchParams, setSearchParams] = useSearchParams();

  // Table Sort
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [sortedRows, setRows] = useState<any[]>([]);

  useEffect(() => {
    setRows(dealerships);
  }, [dealerships]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    header_id: string,
  ) => {
    const isAsc = orderBy === header_id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(header_id);

    handleSort();
  };

  const handleSort = () => {
    let newArray = [...dealerships].sort((a:any, b:any)=>{
      return a[orderBy] === b[orderBy] ? 0 : order==='asc'? a[orderBy] ? -1:1 : b[orderBy] ? 1:-1;
    })
    setRows(newArray);
    
  }

  const handleChange = (event:any, value:any) => {
    setSearchParams({page: value})  
  }

  return (
    <>
          <div className="table">
            {isLoading ? ( 
              <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
            ): 
            
            dealerships.length>0 ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {headers.map((header)=>(
                    <TableCell key={header.id}  >
                      {header.id!=='control' && header.id!=='comments' ? 
                      <TableSortLabel
                        active={orderBy === header.id}
                        direction={orderBy === header.id ? order : 'asc'}
                        onClick={(e)=>handleRequestSort(e,header.id)}
                      >
                        {header.label}
                        {orderBy === header.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                      :
                      (header.label)
                    }

                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
              {sortedRows.map((dealership,i)=>(
                <TableRow key={i}>
              
                    <TableCell  >
                      <div className="clickable-icon" onClick={()=>navigate(`/dealership/${dealership?.id}`)}>
                        {dealership?.name ??"-"}
                      </div>
                    </TableCell>

                    <TableCell  >
                      {dealership?.dealer?.name??""}
                    </TableCell>
                    
                    <TableCell  >
                      {dealership?.country?.label}
                    </TableCell>

                    <TableCell  >
                    {dealership?.region?.label}
                    </TableCell>

                    <TableCell  >
                    {dealership?.city??""}
                    </TableCell>

                    <TableCell  >
                      {dealership?.staff_count??0}
                    </TableCell>

                    <TableCell  >
                      {dealership?.website_url?
                        <a className="clickable-icon" href={dealership?.website_url} target="_blank" rel="noreferrer">
                          <LinkOut/>
                        </a>
                        :
                        <LinkOut hasLink={false}/>
                      }
                    </TableCell>

                    <TableCell  >
                      {dealership?.supplies_url?
                        <a className="clickable-icon" href={dealership?.supplies_url} target="_blank" rel="noreferrer">
                          <LinkOut/>
                        </a>
                        :
                        <LinkOut hasLink={false}/>
                      }
                    </TableCell>
                </TableRow>
                ))}
              </TableBody>

            </Table>
            ):(
            <div className="empty-table">No Dealerships Found.</div>
            )
          }
          </div>

          <div className="table-footer">
            <div className="pagination">
              <Pagination 
                count={1} 
                variant="outlined" 
                onChange={handleChange}
                page={1}
                showLastButton 
                showFirstButton 
                disabled={true}
                />
              <div className="total-count">Showing {dealerships?.length}/{meta?.total??0} Dealerships</div>
            </div>
          </div>  
    </>
  );
};

export default DealershipsTable;
