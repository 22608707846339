import GaugeChart from "react-gauge-chart";
import spaControlStyles from "../../assets/scss/SpaControl.module.scss";
const chartStyle = {
  width: "15rem",
};

interface AmpereMeterProps {
  value: number;
}

export const AmpereMeter: React.FC<AmpereMeterProps> = ({ value }) => {
  return (
    <div className={spaControlStyles.ampereMeterWrapper}>
      <GaugeChart
        id="gauge-chart"
        animateDuration={5000}
        percent={(value/60)} // 60 amps is the max
        style={chartStyle}
        fontSize="1.1rem"
        nrOfLevels={3}
        textColor={"var(--font-black)"}
        cornerRadius={3}
        colors={["#3EE814", "#F7D317", "#F71717"]}
        needleColor={"var(--as-gray)"}
        needleBaseColor={"var(--as-gray)"}
        formatTextValue={(percent) => value + " Amps"}
      />
    </div>
  );
};
