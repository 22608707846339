import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState, useEffect } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import {
  Modal, 
  TextField, 
  CircularProgress,  
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
 } from '@mui/material';
import { fetchCountries, fetchRegions } from "../../../redux/actions/countries.actions";
import { updateDealership } from "../../../redux/actions/dealership.actions";

interface ModalProps {
    isOpen: boolean, 
    setOpen: any,
    dealership: any;
}

const EditDealershipModal: React.FC<ModalProps> = ({isOpen, setOpen, dealership}) => {
  const dispatch: AppDispatch = useDispatch();
  const {error, loading} = useSelector((state: RootState) => state.dealership);
  const {data:countries} = useSelector((state: RootState) => state.countries);
  const {data:regions} = useSelector((state: RootState) => state.regions);

  const [nameInput, setNameInput] = useState("");
  const [cityInput, setCityInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [websiteInput, setWebsiteInput] = useState("");
  const [suppliesInput, setSuppliesInput] = useState("");
  const [regionInput, setRegionInput] = useState("");
  const [countryInput, setCountryInput] = useState("");

  const handleClose = () => {
    handleReset();
    setOpen(false)
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchCountries());
      if (countryInput!=='') {
        dispatch(fetchRegions(countryInput));
      }
    }
  }, [dispatch,isOpen,countryInput]);

  useEffect(() => {
    if (dealership && isOpen){
      setNameInput(dealership.name??"");
      setCityInput(dealership.city??"");
      setRegionInput(dealership.region?.id??"");
      setCountryInput(dealership.country?.id??"");
      setPhoneInput(dealership.phone??"");
      setEmailInput(dealership.email??"");
      setWebsiteInput(dealership.website_url??"");
      setSuppliesInput(dealership.supplies_url??"");
    }
}, [isOpen, dealership]);

  const handleSelect = (event: SelectChangeEvent, selectField: string) => {
    switch (selectField){
      case 'country':
        setCountryInput(event.target.value as string);
        setRegionInput('');
        break;
      case 'region':
        setRegionInput(event.target.value as string);
        break;
    }
  };

  const handleEdit = async () => {
    await dispatch(updateDealership(
      {
        id:dealership?.id, 
        name:nameInput,
        city:cityInput, 
        phone:phoneInput,
        email:emailInput,
        website_url:websiteInput,
        supplies_url:suppliesInput,
        region_id:regionInput,
        country_id:countryInput,
      }));

    if (!error){
      handleClose();
    }
  }

  const handleReset = () => {
    setNameInput("");
    setCityInput("");
    setRegionInput("");
    setCountryInput("");
    setPhoneInput("");
    setEmailInput("");
    setWebsiteInput("");
    setSuppliesInput("");
  }

  return (
    <div>
      <Modal
        open={isOpen}
      >
        {loading ?
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
        :
        <div className={styles.modal}>
            <div className={styles.header}>
                <p><strong>Edit {dealership?.name}</strong></p>
            </div>
            <div className="edit-modal-input">
                <TextField id="name" label="Name" variant="outlined" fullWidth value={nameInput} onChange={(e)=>setNameInput(e.target.value)}/>
                <TextField id="city" label="City" variant="outlined" fullWidth value={cityInput} onChange={(e)=>setCityInput(e.target.value)}/>
                <FormControl fullWidth>
                <InputLabel>Country</InputLabel>
                <Select
                  label="Country"
                  value={countryInput}
                  onChange={e => handleSelect(e,'country')}
                >
                  <MenuItem value={''}>All</MenuItem>
                  { countries?.length>0 &&
                    countries.map((country:any)=>
                      <MenuItem key={country?.id} value={country?.id}>{country?.label}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>{countryInput==='' || regions.length>0?`Region`:`No Regions Found.`}</InputLabel>
                <Select
                  value={regionInput}
                  onChange={e => handleSelect(e,'region')}
                  disabled={regions.length===0}
                >
                  <MenuItem value={''}>All</MenuItem>
                  { regions?.length>0 && countryInput!=='' &&
                    regions.map((region:any)=>
                      <MenuItem key={region?.id} value={region?.id}>{region?.label}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
              <div className="edit-header">Contact</div>
                <TextField id="phone" label="Phone" variant="outlined" fullWidth value={phoneInput} onChange={(e)=>setPhoneInput(e.target.value)}/>
                <TextField id="email" label="Email" variant="outlined" fullWidth value={emailInput} onChange={(e)=>setEmailInput(e.target.value)}/>
                <TextField id="website_url" label="Website" variant="outlined" fullWidth value={websiteInput} onChange={(e)=>setWebsiteInput(e.target.value)}/>
                <TextField id="supplies_url" label="Supplies" variant="outlined" fullWidth value={suppliesInput} onChange={(e)=>setSuppliesInput(e.target.value)}/>
                
            </div>
            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Cancel </button>
                <button className="as-button" onClick={handleEdit} disabled={loading}>                         
                  {loading ? (
                    <CircularProgress size={"24px"} style={{ color: "as-white" }} />
                    ) : (
                    "Save"
                    )}
                </button>
                
            </div>
        </div>}
      </Modal>
    </div>
  );
};

export default EditDealershipModal;
