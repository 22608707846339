import React, {useEffect} from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";

import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchSpaUpgradeRequests } from "../../../redux/actions/upgradeRequests.actions";
import HistoryTable from "../../UpgradeRequests/components/HistoryTable";

interface ReportsTableProps {
    spa_guid: string;
    startDate: string;
    endDate: string;
}


const ReportUpgradeHistory: React.FC<ReportsTableProps> = ({spa_guid}) => {
    const dispatch: AppDispatch = useDispatch();
    const searchParams = useSearchParams()[0];
    const navigate = useNavigate();
    
    useEffect(() => {
        if(spa_guid) {
            dispatch(fetchSpaUpgradeRequests({spa_id:spa_guid,params:searchParams.toString()}))
        }
    }, [dispatch, searchParams, spa_guid]);

  return (
    <>
          <div className="table-section">
            <HistoryTable page='reports'/>
            <div className="filter-btn-group spa-actions">
              <button 
                onClick={()=>navigate(`/upgrade-requests`)}>
                  View All Requests
                </button>
              </div>
          </div>
    </>
  );
};

export default ReportUpgradeHistory;
