import "../SpaDetailsPage.css"
import React from "react";
import { useSelector } from "react-redux";
import RootState from "../../../redux/states/root.state";
import { SpaSettingsLabels } from "../../../mqttData/settings/spaSettingsLabels";
import { SpaConstLabels } from "../../../mqttData/consts/spaConstsLabels.enums";

const SpaCard: React.FC<{editMode:any, newSettings:any,setNewSettings:any}> = ({editMode, newSettings, setNewSettings}) => {
    const spa = useSelector((state: RootState) => state.spa.data);
    // Spa data and settings:
    const settingsData = useSelector((state: RootState) => state.settings.data);
    const constData = useSelector((state: RootState) => state.const.data);
  
  return (
    <>
      <div className="card-container spa">
        <div className="grid-item set">
            <div className="grid-item id">
                <div>GUID</div>
                <div>SN</div>
                <div>Nickname</div>
            </div>

            <div className="grid-item values">
                <div>{spa?.id??`-`}</div>
                <div>{spa.arctic_serial_number??"N/A"}</div>
                <div>{spa?.nick_name??`n/a`}</div>
            </div>  
        </div>


        <div className="grid-item set">
            <div className="grid-item id">
                <div>Yocto</div>
                <div>LPC</div>
                <div>SpaBoy</div>
            </div>

            <div className="grid-item values">
                <div>{spa?.yocto_version??`--`}</div>
                <div>{settingsData[SpaSettingsLabels.LPCFWVer]??`--`}</div>
                <div>{settingsData[SpaSettingsLabels.SBFWVer]??`--`}</div>
            </div>
        </div>

        <div className="grid-item set">
            <div className="grid-item id">
                <div>RFIDa</div>
                <div>RFIDb</div>
                <div>RFIDComs</div>
            </div>

            <div className="grid-item values">
                <div>{settingsData[SpaSettingsLabels.TAG1]??'--'}</div>
                <div>{settingsData[SpaSettingsLabels.TAG2]??'--'}</div>
                <div>{settingsData.RFIDConnected ? `CONNECTED`: `DISCONNECTED`}</div>
            </div>
        </div>

        <div className="grid-item set">
            <div className="grid-item id">
                <div>Temp. Offset</div>
                <div>ORP Offset</div>
                <div>pH Offset</div>
            </div>

            <div className="grid-item values">
                {editMode ?
                    <div>
                        <input id="Toff" type="number" defaultValue={settingsData.Toff} min={constData[SpaConstLabels.setTOffmin]} max={constData[SpaConstLabels.setTOffmax]} 
                            onChange={(e)=>setNewSettings({...newSettings, setTOff: Number(e.target.value)})}/> °F
                    </div>
                    :
                    <div>{settingsData.Toff ?? 0}°F</div>
                }
                {editMode ?
                    <div>
                        <input id="ORPoff" type="number" defaultValue={settingsData[SpaSettingsLabels.cfgORPoff] ?? 0} min={constData[SpaConstLabels.cfgORPoffmin]} max={constData[SpaConstLabels.cfgORPoffmax]} 
                            onChange={(e)=>setNewSettings({...newSettings, cfgORPoff: Number(e.target.value)})} step={5}/>
                    </div>
                    :
                    <div>{settingsData[SpaSettingsLabels.cfgORPoff] ?? 0}</div>
                }
                {editMode ?
                    <div>
                        <input id="pHoff" type="number" defaultValue={settingsData[SpaSettingsLabels.cfgpHoff] ?? 0} min={constData[SpaConstLabels.cfgpHoffmin]} max={constData[SpaConstLabels.cfgpHoffmax]} 
                            onChange={(e)=>setNewSettings({...newSettings, cfgpHoff: Number(e.target.value)})}/>
                    </div>
                    :
                    <div>{settingsData[SpaSettingsLabels.cfgpHoff] ?? 0}</div>
                }
            </div>
        </div>
      </div>
    </>
  );
};

export default SpaCard;
