import "./CommandsPage.css"
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import Header from "../../components/common/Header/Header";
import { fetchCommands } from "../../redux/actions/commands.actions";
import CommandsContainer from "./components/CommandsContainer";
import AddCommandModal from "./components/AddCommandModal";
import UpdateCommandModal from "./components/UpdateCommandModal";
import DeleteCommandModal from "./components/DeleteCommandModal";

const CommandsPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [commandToModify, setCommandToModify] = useState(null);

  useEffect(() => {
    dispatch(fetchCommands(searchParams.toString()));
  }, [dispatch,searchParams]);

  return (
    <>
    <AddCommandModal 
      open={addOpen} 
      setOpen={setAddOpen} 
      params={searchParams.toString()}
    />

    <UpdateCommandModal 
      open={editOpen} 
      setOpen={setEditOpen} 
      command={commandToModify} 
      setCommandToModify={setCommandToModify} 
      params={searchParams.toString()}
    />

    <DeleteCommandModal 
      open={deleteOpen} 
      setOpen={setDeleteOpen} 
      command={commandToModify} 
      setCommandToModify={setCommandToModify} 
      params={searchParams.toString()}
    />

    <Header/>
    <CommandsContainer 
      setAddOpen={setAddOpen}
      setEditOpen={setEditOpen}
      setDeleteOpen={setDeleteOpen}
      setCommandToModify={setCommandToModify}
    />
    </>
  );
};

export default CommandsPage;
