import axios from 'axios';
import { BASE_URL } from './apiconfig';

export const getDealerships = async (token: string | null, params:string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships?${params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  } catch (error) {
    console.log("Failed to pull dealerships list. ", error);
    throw error;
  }
}

export const getDealership = async (token: string | null, id: string) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull dealership by id. ", error);
    throw error;
  }
}

export const getStaff = async (token: string | null, getInfo:{id:string, params:string}) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/dealerships/${getInfo.id}/staff?${getInfo.params}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to pull dealership staff. ", error);
    throw error;
  }
}

export const putDealership = async (token: string | null, 
dealership:{
  id:string;
  name:string;
  city:string;
  phone:string;
  email:string;
  website_url:string;
  supplies_url:string;
  region_id:string;
  country_id:string;
}) => {
  try {
    const { data } = await axios.put(`${BASE_URL}/dealerships/${dealership.id}`,{
      name:dealership.name, 
      city:dealership.city, 
      phone:dealership.phone,
      email:dealership.email,
      website_url:dealership.website_url,
      supplies_url:dealership.supplies_url,
      region_id:dealership.region_id,
      country_id:dealership.country_id,
    },{
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (error) {
    console.log("Failed to update dealership. ", error);
    throw error;
  }
}