import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, Input, CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { fetchAvailableFirmware, uploadFirmware } from '../../../redux/actions/firmware.actions';

interface UploadFirmwareModalProps {
    isOpen: boolean, 
    setOpen: any,
    params: string
}

const UploadFirmwareModal: React.FC<UploadFirmwareModalProps> = ({isOpen, setOpen,params}) => {
    const handleClose = () => {
      setClicked(false);
      setFile(null);
      setType('yocto');
      setVersion('');
      setDescription('');
      setOpen(false);
    };

    const handleReset = () => {
      setClicked(false);
      setFile(null);
      setType('yocto');
      setVersion('');
      setDescription('');
    };

    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const {data, error} = useSelector((state: RootState) => state.firmwareUpload);
    const [uploadClicked, setClicked] = useState(false);
    const [inProgress, setInProgress] = useState(false);
  
    const [file, setFile] = useState<File | null>(null);
    const [type, setType] = useState<'yocto' | 'lpc' | 'spaboy'>('yocto');
    const [version, setVersion] = useState('');
    const [description, setDescription] = useState('');
  
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        setFile(event.target.files[0]);
      }
    };
  
    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      if (!file || !accessToken) return;
      const formData = new FormData();
      formData.set("file",file);
      formData.set("type",type);
      formData.set("version",version);
      formData.set("description",description);

      setClicked(true);
      setInProgress(true);
      await dispatch(uploadFirmware(formData));
      dispatch(fetchAvailableFirmware(params));
      setInProgress(false);
    };

if (!uploadClicked || inProgress){
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Upload Firmware
          </div>

            <div className={styles.body}>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                  <Box>
                    <Input
                      type="file"
                      id="file"
                      inputProps={{ accept: '.bin,.swu,.s19,.pages' }}
                      onChange={handleFileChange}
                      required
                      disabled={inProgress}
                    />
                  </Box>
                  <Typography variant="caption" sx={{ mt: 1, color:'var(--font-gray)' }}>
                    Note: Files will be renamed to "{type}_{version}.extension" when uploaded.
                  </Typography>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <Select
                    labelId="type-label"
                    id="type"
                    value={type}
                    onChange={(e) => setType(e.target.value as 'yocto' | 'lpc' | 'spaboy')}
                    required
                    disabled={inProgress}
                  >
                    <MenuItem value="yocto">Yocto</MenuItem>
                    <MenuItem value="lpc">LPC</MenuItem>
                    <MenuItem value="spaboy">Spa Boy</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="version"
                    label="Version"
                    value={version}
                    onChange={(e) => setVersion(e.target.value)}
                    required
                    disabled={inProgress}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="description"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    rows={4}
                    required
                    disabled={inProgress}
                  />
                </FormControl>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={inProgress || !accessToken}
                  sx={{ mt: 2 }}
                >
                  {inProgress ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Upload Firmware"
                    )} 
                </Button>
              </form>
            </div>

            <div className="modal-btn-group">
                <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (error){
    return (
      <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{data.message}</div>
          <div className="modal-btn-group">
              <button className="as-text" onClick={handleClose}> Close </button>
              <button className="as-text" onClick={handleReset}> Upload Another </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default UploadFirmwareModal;
