import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import AccessDenied from '../../pages/General/AccessDenied';
import RootState from '../../redux/states/root.state';
import roleCheck from './roleCheck';

interface ProtectedRouteProps {
    children: React.ReactNode;
    roles: string[];
}

const RoleRoute: React.FC<ProtectedRouteProps> = ({children,roles}) => {
    const {data:user, accessToken} = useSelector((state: RootState) => state.user);
    const [canAccess, setCanAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
        }

        setCanAccess(
            roleCheck(user, roles)
        )

    }, [accessToken, user, roles, navigate]);


    return (accessToken && canAccess) ? <>{children}</> : <AccessDenied />

};

export default RoleRoute;