import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UsersState from '../states/spas.state';
import { fetchUsers } from '../actions/users.actions';

const initialState: UsersState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const UsersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default UsersSlice.reducer;