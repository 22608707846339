import "./Home.css"
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/store";
import { logout } from "../../redux/actions/user.actions";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import { 
  SettingIcon, 
  TubIcon, 
  AccountIcon, 
  UsersIcon, 
  DealershipIcon, 
  LogoutIcon,
  FirmwareIcon,
  LogsIcon,
  CommandsIcon,
  PermissionsIcon,
  UserGroupIcon,
  RolesIcon,
  CommentsIcon,
} from "../../assets/svg/svg";
import {
  CircularProgress
} from "@mui/material";
import roleCheck from "../../components/Layout/roleCheck";

const HomePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const canAccessDeveloperPanel = roleCheck(user,["admin","developer"]);

  const isLoading = useSelector((state: RootState) => state.user.loading);
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
  };
  
  return (
    <>
    <Header withBackButton={false} withNavBtn={false} withUserAvatar={false}/>
      <div className="page-wrapper">
        {isLoading ? <CircularProgress/>:
          <div className="center-nav">
              <p>Welcome, {user?.name ?? `User`}</p>
              <div className="nav-btn-group">

                <button onClick={()=>navigate(`/spas`)}>
                  <TubIcon/>
                  Spas
                </button>

                <button onClick={()=>navigate(`/users`)}>
                  <UsersIcon/>
                  Users
                </button>

                <button onClick={()=>navigate(`/dealerships`)}>
                  <DealershipIcon/>
                  Dealerships
                </button>

                
                <button onClick={()=>navigate(`/comments`)}>
                  <CommentsIcon/>
                  Comments
                </button>

                <button onClick={()=>navigate(`/logs`)}>
                  <LogsIcon/>
                  Log History
                </button>

                {canAccessDeveloperPanel && <>
                  <button onClick={()=>navigate(`/firmware`)}>
                    <FirmwareIcon/>
                    Firmware
                  </button>

                  <button onClick={()=>navigate(`/spa-commands`)}>
                    <CommandsIcon/>
                    Spa Commands
                  </button>

                  <button onClick={()=>navigate(`/roles`)}>
                    <RolesIcon/>
                    User Roles
                  </button>

                  <button onClick={()=>navigate(`/user-groups`)}>
                    <UserGroupIcon/>
                    User Groups
                  </button>

                  <button onClick={()=>navigate(`/permissions`)}>
                    <PermissionsIcon/>
                    Permissions
                  </button>
                </>}

                <button onClick={()=>navigate(`/settings`)}>
                  <SettingIcon/>
                  Settings
                </button>

                <button className={`logout-button ${canAccessDeveloperPanel?``:`full-row`}`} onClick={handleLogout}>
                  <LogoutIcon/>
                  Logout
                </button>

              </div>
            </div>
          }  
      </div>
    </>
  );
};

export default HomePage;
