import styles from "../../../assets/scss/Modal.module.scss";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, TextField, Button, FormControl, CircularProgress,Checkbox, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, Typography, Divider } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { fetchComment, fetchComments, fetchSpaComments, markAsRead, resolveComment, updateComment } from "../../../redux/actions/comments.actions";
import { useParams, useSearchParams } from "react-router-dom";
import roleCheck from "../../../components/Layout/roleCheck";

interface ModalProps {
    open: boolean, 
    setOpen: any,
    comment_id: string,
}

const ViewCommentModal: React.FC<ModalProps> = ({open, setOpen, comment_id}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { data:user, accessToken } = useSelector((state: RootState) => state.user);
    const { data:comment,error, loading } = useSelector((state: RootState) => state.comment);
    const {id} = useParams();
    const searchParams = useSearchParams()[0];
    
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [category, setCategory] = useState('');
    const [resolved, setResolved] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canResolve, setCanResolve] = useState(false);

    /* Get current user data */
    useEffect(() => {
      if (comment_id && open){
        dispatch(fetchComment(comment_id));
      }
    }, [dispatch,comment_id,open]);

    /* Get current user data */
    useEffect(() => {
      if (comment){
        setTitle(comment.title);
        setCategory(comment.category);
        setBody(comment.body);
        setResolved(comment.is_resolved!==0);
        handleRead();
        setCanEdit(comment?.user?.id===user?.id || roleCheck(user,["admin"]) );
        setCanResolve(comment?.user?.id===user?.id || roleCheck(user,["admin, developer"]) );
      }
    }, [comment, user]);

    const handleSubmit = async () => {
      await dispatch(updateComment({id:comment?.id, title:title, category:category,body:body, is_resolved:resolved?1:0}));
      if(!error){
        handleReset();
        id ? await dispatch(fetchSpaComments({id:id, params:searchParams.toString()})) : await dispatch(fetchComments(searchParams.toString()));
      }
    };

    const handleClose = () => {
      handleReset();
      setOpen(false);
    };

    const handleRead = async () => {
      if (comment?.is_unread) {
        await dispatch(markAsRead({id:comment?.id, spa_id:comment?.spa_id, params:searchParams.toString(), page:id}));
      }
    };

    const handleResolve = async () => {
      setResolved(!resolved);
      await dispatch(resolveComment({id:comment?.id,is_resolved:!resolved?1:0}));
      if(!error){
        id ? await dispatch(fetchSpaComments({id:id, params:searchParams.toString()})) : await dispatch(fetchComments(searchParams.toString()));
      }
    };

    const handleReset = () => {
      setTitle('');
      setBody('');
      setCategory('');
      setResolved(false);
      setEditMode(false);
    };

if (!editMode && loading){
      return (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
          >
            <div className={styles.modal}>
              <div className={styles.header}>
                 Loading Comment...
              </div>
    
                <div className={styles.body}>
                  <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
                </div>
            </div>
          </Modal>
        </div>
 )} else if (error){
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
          <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else if (editMode) {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <div className={styles.modal}>
            <div className={styles.header}>
                <div>Comment <b>{comment?.id}</b></div>
                <small>by <b>{comment?.user?.username}</b></small>
            </div>
  
              <div className={styles.body}>
              <div className="modal-form">
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="title"
                    label="Title"
                    value={title??""}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={loading}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "var(--font-black)",
                    },
                  }}
                  />
                </FormControl>
                <FormControl fullWidth margin="normal" required disabled={loading}>
                <InputLabel>Category</InputLabel>
                  <Select
                    label="Category"
                    value={category??""}
                    onChange={e => setCategory(e.target.value as string)}
                    defaultValue=""
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "var(--font-black)",
                    }
                  }}
                  >
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    id="body"
                    label="Body"
                    value={body??""}
                    onChange={(e) => setBody(e.target.value)}
                    multiline
                    required
                    disabled={loading}
                    minRows={4}
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "var(--font-black)",
                    },
                  }}
                  />
                </FormControl>
                <FormGroup>
                  <FormControlLabel
                  label="Resolved"
                  control={
                    <Checkbox
                      checked={resolved??false}
                      onChange={(e) => setResolved(e.target.checked)}
                      disabled={loading||!editMode}
                    />
                  } />
                </FormGroup>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={loading || !accessToken || title===''||body===''}
                  sx={{ mt: 2 }}
                >
                {loading ? (
                    <CircularProgress size={"24px"} style={{ color: "var(--pure-white)" }} />
                    ) : (
                    "Update Comment"
                  )} 
                </Button>
              </div>
              </div>
  
              <div className="modal-btn-group">
                 <button className="as-text" onClick={(e)=>{setEditMode(false)}}> Cancel </button>
              </div>
          </div>
        </Modal>
      </div>)
  } else {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <div className={styles.modal}>
            <div className={styles.header}>
                {!canEdit ?<div></div> :<button className="edit-comment" onClick={()=>setEditMode(true)}> Edit </button>}
                <Typography variant='h6' sx={{fontWeight:'bold'}}>{comment?.title}</Typography>
                <small>by <b>{comment?.user?.username}</b></small>
                <Typography variant='subtitle1' gutterBottom={true}>{comment?.category}</Typography>
            </div>
  
              <div className={styles.body}>
              <div className="modal-form">
                <Divider sx={{background:'var(--as-gray)', marginBottom:'1rem'}} variant="middle"/>
                <Typography gutterBottom={true} paragraph={true} sx={{color:'var(--font-black)'}}>{comment?.body}</Typography>
              </div>
              </div>
  
              <div className="modal-btn-group">
                 <button className="as-text" onClick={handleClose}> Close </button>
                 {!canResolve ?<div></div> :<button className="as-text" onClick={()=>handleResolve()}> {resolved?`Mark as Unresolved`:`Mark as Resolved`} </button>}
              </div>
          </div>
        </Modal>
      </div>)
  }
};

export default ViewCommentModal;

/*
	'body1'
| 'body2'
| 'button'
| 'caption'
| 'h1'
| 'h2'
| 'h3'
| 'h4'
| 'h5'
| 'h6'
| 'inherit'
| 'overline'
| 'subtitle1'
| 'subtitle2'
| string
*/