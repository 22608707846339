import styles from "../../../assets/scss/Modal.module.scss";
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Modal } from '@mui/material';
import { AppDispatch, RootState } from '../../../redux/store'; 
import { deleteCommand } from "../../../redux/actions/commands.actions";

interface ModalProps {
  open: boolean, 
  setOpen: any,
  command: any,
  setCommandToModify: any,
  params: string
}

const DeleteCommandModal: React.FC<ModalProps> = ({open, setOpen,command,setCommandToModify,params}) => {
    const handleClose = () => {
      handleReset();
      setOpen(false);
      setCommandToModify(null);
    };

    const handleReset = () => {
      setSubmitted(false);
    };

    const dispatch = useDispatch<AppDispatch>();
    const { accessToken } = useSelector((state: RootState) => state.user);
    const { error, loading } = useSelector((state: RootState) => state.commands);
    
    const [submitted, setSubmitted] = useState(false);

    const handleDelete = async (e: React.FormEvent) => {
      e.preventDefault();
      setSubmitted(true);
      await dispatch(deleteCommand({command_id: command?.id, params: params}));
    };

if (!submitted && !loading){
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
              Delete <strong>{command?.title}</strong>?
          </div>

            <div className={styles.body}>
              {`This action cannot be undone.`}
            </div>

            <div className="modal-btn-group">
              {!loading ? <button className={styles.delete} onClick={handleDelete} disabled={!accessToken}> {`Delete`} </button> : <p></p>}
              <button className="as-text" onClick={handleClose}> Close </button>
            </div>
        </div>
      </Modal>
    </div>
  )} else if (loading && !error) {
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
        >
          <div className={styles.modal}>
            <div className={styles.header}>
                Deleting...
            </div>
  
              <div className={styles.body}>
                <br></br>
                <CircularProgress size={"24px"} />
              </div>
          </div>
        </Modal>
      </div>
      )
  } else if (error){
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>An Error Occurred</div>
          <div className={styles.body}><p>{error}</p></div>
          <div className="modal-btn-group">
            <button className="as-text" onClick={handleClose}> Close </button>
            <button className="as-text" onClick={handleReset}> Try Again </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  } else {
    return (
      <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Success!</div>
          <div className={styles.body}>{`Command successfully deleted.`}</div>
          <div className="modal-btn-group">
              <p></p>
              <button className="as-text" onClick={handleClose}> OK </button>
          </div>
        </div>
      </Modal>
    </div>
    )
  }
};

export default DeleteCommandModal;
