import { createAsyncThunk } from '@reduxjs/toolkit';
import RootState from "../states/root.state";
import { deleteCommentApi, getComment, getComments, getSpaComments, postComment, putComment, readComment, resolveCommentApi } from '../../services/api/comments.api';

export const fetchComments = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'comments',
    async (params, {rejectWithValue, getState}) => {
        try {
        const {user} = getState();
        const token = user.accessToken;

        const response = await getComments(token, params);
        return response.data;

        
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    }
);

export const fetchComment = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
    'comment',
    async (id, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await getComment(token, id);
            return response.data;

            
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during removal.');
            }
        }
    }
);

export const updateComment = createAsyncThunk<any, {id:string, title:string, category:string, body:string, is_resolved:any}, { rejectValue: string; state: RootState}>(
    'comment/update',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await putComment(token, updateInfo).then(()=>{return getComment(token, updateInfo.id)});

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during updating.');
            }
        }
    } 

);

export const resolveComment = createAsyncThunk<any, {id:string,is_resolved:any}, { rejectValue: string; state: RootState}>(
    'comment/resolve',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await resolveCommentApi(token, updateInfo).then(()=>{return getComment(token, updateInfo.id)});

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during updating.');
            }
        }
    } 

);

export const markAsRead = createAsyncThunk<any, {id:string, params:string, spa_id:string, page:string|undefined}, { rejectValue: string; state: RootState}>(
    'comment/read',
    async (updateInfo, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;

            const response = await readComment(token, updateInfo).then(()=>{return updateInfo.page ? getSpaComments(token,{id:updateInfo.page, params:updateInfo.params}) : getComments(token,updateInfo.params)});

            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during updating.');
            }
        }
    } 

);

export const fetchSpaComments = createAsyncThunk<any, {id:string, params:string}, { rejectValue: string; state: RootState}>(
    'spa/comments',
    async (request, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await getSpaComments(token, request);
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during fetching.');
            }
        }
    }
);

export const addComment = createAsyncThunk<any, {title:string, category:string, body:string, spa_id:string, user_id:string, params:string}, { rejectValue: string; state: RootState}>(
    'comments/post',
    async (comment, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await postComment(token, comment).then(()=>{return getSpaComments(token,{id:comment.spa_id,params:comment.params})});
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during posting.');
            }
        }
    }
);

export const deleteComment = createAsyncThunk<any, {id:string, spa_id:string,params:string}, { rejectValue: string; state: RootState}>(
    'comment/delete',
    async (comment, {rejectWithValue, getState}) => {
        try {
            const {user} = getState();
            const token = user.accessToken;
    
            const response = await deleteCommentApi(token, comment.id).then(()=>{return getSpaComments(token,{id:comment.spa_id,params:comment.params})});
            return response.data;

        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data.message);
            } else {
                return rejectWithValue('An error occurred during deletion.');
            }
        }
    }
);