import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDealerships } from '../actions/dealership.actions';
import DealershipsState from '../states/dealerships.state';

const initialState: DealershipsState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const DealershipsSlice = createSlice({
  name: 'dealerships',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealerships.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDealerships.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchDealerships.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default DealershipsSlice.reducer;