import React, {useEffect} from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CommandsTable from "../../Commands/components/CommandsTable";
import { fetchSpaCommands } from "../../../redux/actions/commands.actions";

interface ReportsTableProps {
    spa_guid: string;
    startDate: string;
    endDate: string;
}

const SpaCommandsTable: React.FC<ReportsTableProps> = ({spa_guid}) => {
    const dispatch: AppDispatch = useDispatch();
    const searchParams = useSearchParams()[0];

    useEffect(() => {
        console.log(searchParams.toString())
        dispatch(fetchSpaCommands({spa_id:spa_guid, params:searchParams.toString()}))
    },[dispatch,spa_guid,searchParams]);
    
  return (
    <>
        <CommandsTable page='reports'/>
    </>
  );
};

export default SpaCommandsTable;
