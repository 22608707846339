import { SpaErrorLabels } from "../../mqttData/errors/spaErrorLabels.enums";
import { SpaStatusLabels } from "../../mqttData/status/spaStatusLabels.enums";
import { ArcticLabels } from "../SpaErrors/components/arcticLabels.enums";

const sortOptions = (options:any[])=>{
  return options.sort((a:any,b:any)=>{return a.label===b.label ? 0 :a.label>b.label ? 1 : -1})
}
export const liveOptions = sortOptions([
    {id:`STemp`, label:`Spa Temp.`},
    {id:`TSP`, label:`Water Temp. Set Point`},
    {id:`HTemp`, label:`Heater Temp.`},
    {id:`SBConnected`, label:`SpaBoy Connection`},
    {id:`Filter`, label:`Filter State`},
    {id:`P1`, label:`Pump 1 State`},
    {id:`H1`, label:`Heater State`},
    {id:`Current`, label:`Pack Current`},
    {id:`sbVout`, label:`Evolts`},
    {id:`sbI1`, label:`E1 Amps`},
    {id:`sbI2`, label:`E2 Amps`},
    {id:`sbpH`, label:`pH`},
    {id:`sbORP`, label:`ORP`},
  ]);

export const settingsOptions = sortOptions([
    {id:`SBConnected`, label:`SB Connection`},
    {id:`RFIDConnected`, label:`RFID Connection`},
  ]);

export const firmwareOptions = sortOptions([
    {id:`LPCFWVer`, label:`LPC Versions`},
    {id:`SBFWVer`, label:`SpaBoy Versions`},
  ]);

export const RFIDOptions = sortOptions([
    {id:`tag1`, label:`Tag 1`},
    {id:`tag2`, label:`Tag 2`},
  ]);

export const spaSetOptions = sortOptions([
    {id:`TSP`, label:`Temperature Set Point`},
    {id:`FF`, label:`Filter Frequence`},
    {id:`OnHr`, label:`Onzen Hours`},
    {id:`OnCy`, label:`Onzen Cycles`},
    {id:`OzHr`, label:`Ozone Hours`},
    {id:`OzCy`, label:`Ozone Cycles`},
    {id:`SBHr`, label:`SpaBoy Hours`},
    {id:`Toff`, label:`Temperature Offset`},
    {id:`FS`, label:`Filter Suspend`},
  ]);

export const spaBoyOptions = sortOptions([
    {id:`SBORPhi`, label:`ORP High`},
    {id:`SBORPlo`, label:`ORP Low`},
    {id:`SBpHhi`, label:`pH High`},
    {id:`SBpHlo`, label:`pH Low`},
  ]);

export const errorOptions = Object.values(SpaErrorLabels).map((err)=>(
  {id:err, label:ArcticLabels[err]!==""?`${err} - ${ArcticLabels[err]}`:`${err}`}
));

export const statusOptions = Object.values(SpaStatusLabels).map((stat)=>(
  {id:stat, label:ArcticLabels[stat]!==""?`${stat} - ${ArcticLabels[stat]}`:`${stat}`}
));

export const connectionOptions = sortOptions([
    {id:`versionNumber`, label:`Version Number`},
    {id:`ipAddress`, label:`IP Address`},
    {id:`disconnectReason`, label:`Disconnected Reason`},
    {id:`eventType`, label:`Event Type`},
  ]);

  
export const upgradeRequestsOptions = sortOptions([
  {id:`upgradeRequests`, label:`Upgrade Requests`},
]);

export const commandHistoryOptions = sortOptions([
  {id:`commandHistory`, label:`Command History`},
]);

