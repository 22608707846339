import styles from "../../../assets/scss/Modal.module.scss";
import React, {useState, useEffect} from "react";
import {Modal, CircularProgress} from '@mui/material';
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { deleteSpa } from "../../../redux/actions/spa.actions";
import { useSearchParams } from "react-router-dom";

interface DeleteSpaModalProps {
    open: boolean, 
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    spa: any;
    setSpaToModify: any;
    params?: string;
}

const DeleteSpaModal: React.FC<DeleteSpaModalProps> = ({open, setOpen, spa,setSpaToModify,params=""}) => {
  const dispatch: AppDispatch = useDispatch();
  const [inProgress, setInProgress] = useState(false);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const searchParams = useSearchParams()[0];

  /* Get current user data */
  useEffect(() => {
    if (open){
        setError(false)
        setSuccess(false); 
    }
  }, [open,setError,setSuccess]);

  const handleClose = () => {
    setSpaToModify(null);
    setOpen(false);
  };

  const handleDelete = async () => {
    setInProgress(true);

    if(spa){
      const result = await dispatch(
          deleteSpa({guid:spa?.id, params:searchParams.toString()})
        );

      switch (result.type){
          case "spa/delete/fulfilled":
              setSuccess(true);
              break;

          case "spa/delete/rejected":
              setError(true);
              break;
      }
    }
    setInProgress(false);
  }

  return (
    <div>
    <Modal
      open={open}
      onClose={handleClose}
    >

      <div className={styles.modal}>
          <div className={styles.header}>
              {error?
                <p>Error</p>
              :
              success ?
                <p>Success</p>

              : inProgress ?
                <p>Deleting...</p>
              :
                <p> Delete Spa <b>{spa?.nick_name}</b>? </p>
              }
              
          </div>
          <div className={styles.body}>
              {error?
                <div>An error occured while deleting, please try again later.</div>
              :
              success ?
                <div>Spa successfully deleted.</div>
              :
              inProgress ?
                <CircularProgress size={"24px"} />
              :
                <p> This action cannot be undone. </p>
              }
          </div>
          {
              error || success ?
              <div className="modal-btn-group">
                  <div></div>
                  <button className="as-text" onClick={handleClose}> OK </button>                        
          </div>
          : !inProgress ?
          <div className="modal-btn-group">
                <button className={styles.delete} onClick={handleDelete}> Delete </button>
                <button className="as-text" onClick={handleClose}> Cancel </button>
          </div>
          :
          <></>
          }
      </div>
    </Modal>
  </div>
);
};
    
export default DeleteSpaModal;
