import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    fetchUserData,
    loginUser,
    refreshAccessToken,
    clearUserState,
    logout,
    registerUser,
    resetPassword,
    requestResetLink,
    editProfile,
    changePassword
} from '../actions/user.actions';
import UserState from "../states/user.state";


const initialState: UserState = {
    data: null,
    loading: false,
    error: null,
    isLoggedIn: !!localStorage.getItem('accessToken'),
    accessToken: localStorage.getItem('accessToken') || null,
    expirationTime: localStorage.getItem('expirationTime')
        ? parseInt(localStorage.getItem('expirationTime')!, 10)
        : null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder
            // Fetch user data
            .addCase(fetchUserData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserData.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
                state.error = null;
            })
            .addCase(fetchUserData.rejected, (state, action) => {
                state.loading = false;
                state.accessToken = null;
                state.expirationTime = null;
            })

            // Login:
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.isLoggedIn = true;
                state.data = action.payload.user;
                state.accessToken = action.payload.accessToken;
                state.expirationTime = action.payload.expirationTime;
                state.error = null;
                localStorage.setItem('accessToken', action.payload.accessToken);
                localStorage.setItem('expirationTime', action.payload.expirationTime.toString());
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            // Refresh token:
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.accessToken = action.payload.accessToken;
                localStorage.setItem('accessToken', action.payload.accessToken);
                localStorage.setItem('expirationTime', action.payload.expirationTime.toString());
            })

            // Logout:
            .addCase(logout.fulfilled, (state) => {
                state.accessToken = null;
                state.isLoggedIn = false;
                state.data = null;
                state.error = null;
                state.expirationTime = null;
                localStorage.removeItem('accessToken');
                localStorage.removeItem('expirationTime');
            })

            // Clear user state:
            .addCase(clearUserState, (state) => {
                state.accessToken = null;
                state.isLoggedIn = false;
                state.data = null;
                state.error = null;
                state.expirationTime = null;
                localStorage.removeItem('accessToken');
                localStorage.removeItem('expirationTime');
            })

            // Register:
            .addCase(registerUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.loading = false;
                state.isLoggedIn = true;
                state.data = action.payload.user;
                state.accessToken = action.payload.accessToken;
                state.expirationTime = action.payload.expirationTime;
                state.error = null;
                localStorage.setItem('accessToken', action.payload.accessToken);
                localStorage.setItem('expirationTime', action.payload.expirationTime.toString());
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            // Reset password:
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            // Request password link:
            .addCase(requestResetLink.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(requestResetLink.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(requestResetLink.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            // Edit Profile:
            .addCase(editProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.user;
                state.error = null;
            })
            .addCase(editProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            // Change Password:
           .addCase(changePassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.loading = false;
                state.isLoggedIn = true;
                state.error = null;
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export default userSlice.reducer;