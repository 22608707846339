import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRoleUsers } from '../actions/roles.actions';
import { fetchUserGroupUsers } from '../actions/userGroups.actions';
import { fetchStaff } from '../actions/dealership.actions';
import RoleUsersState from '../states/roleUsers.state';

const initialState: RoleUsersState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const RolesUsersSlice = createSlice({
  name: 'role_user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoleUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchRoleUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(fetchUserGroupUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserGroupUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchUserGroupUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(fetchStaff.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchStaff.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      });
  },
});

export default RolesUsersSlice.reducer;