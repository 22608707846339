import "./SpaReport.css"
import React, {useEffect, useState} from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import { AppDispatch } from "../../redux/store";
import { fetchSpa } from "../../redux/actions/spa.actions";
import Header from "../../components/common/Header/Header";
import ReportsGraph from "./components/ReportsGraph";
import ReportsTable from "./components/ReportsTable";
import NotFound from "../General/NotFound";
import { commandHistoryOptions, connectionOptions, errorOptions, firmwareOptions, liveOptions, RFIDOptions, settingsOptions, spaBoyOptions, spaSetOptions, statusOptions, upgradeRequestsOptions } from "./options";
import ReportUpgradeHistory from "./components/ReportUpgradeHistory";
import SpaCommandsTable from "./components/SpaCommandsTable";

const SpaReport: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const {id} = useParams();
  var last_week = new Date();
  last_week.setDate(last_week.getDate() - 7)

  const {data: spa, error, isConnected} = useSelector((state: RootState) => state.spa);
  const {loading} = useSelector((state: RootState) => state.spaHistory);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selection, setSelection] = useState<any[]>([]);
  const [category, setCategory] = useState("");
  const [startInput, setSInput] = useState(last_week);
  const [endInput, setEInput] = useState(new Date());

  const [startDate, setStart] = useState<any>(null);
  const [endDate, setEnd] = useState<any>(null);
  const [dataToShow, setShow] = useState("");
  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    if (id){
      dispatch(fetchSpa(id));
    }
  }, [dispatch, id]);

  const handleGenerate = () => {
    searchParams.delete('page');
    searchParams.set('from', `${new Date(startInput).getTime()}`);
    searchParams.set('until', `${new Date(endInput).getTime()}`);
    setSearchParams(searchParams);

    setSelected(selection);
    setStart(startInput);
    setEnd(endInput);
    setShow(category);
  }

  const handleChange = (e:any) =>{
    setCategory(e.target.value);
    setSelection([]);
  }

  const handleCheck = (opt:any)=>{
    let newArray = [...selection];
    let found = newArray.find((s:any)=>s===opt.id)!==undefined;
    if (found) {
      newArray = selection.filter((s:any)=>
        s!==opt.id
      )
    } else {
      newArray.push(opt.id);
    }
    setSelection(newArray);
  }

  const handleDateChange = (e:any) => {
    let val = e.target.value;
    var date = new Date();
    switch(val){
      case "":
        date.setDate(date.getDate() - 7);
        setSInput(date);
        break;

      case "today":
        date.setUTCHours(0,0,0,0)
        setSInput(date);
        break;

      case "last_month":
        date.setMonth(date.getMonth()-1);
        setSInput(date);
        break;

      case "six_months":
        date.setMonth(date.getMonth()-6);
        setSInput(date);
        break;

      case "last_year":
        date.setFullYear(date.getFullYear() - 1);
        setSInput(date);
        break;
    }
  }

  if (error){
    return <NotFound/>
  }

  return (
    <>
      <Header/>
      <div className="container">
        <div className="reports-header">
            <div>
              {spa?.nick_name}
              {isConnected ? 
                (
                  <span className="status-indicator online"></span>
                ) : (
                  <span className="status-indicator offline"></span>
                )
              }
            </div>
            <div>{spa.id}</div>
          </div>

        <div className="list reports">
          <div className="filters">
            <div className="search">
                
            <div className="date-input">
                <select className="select-style" name="report-category" id="report-select" onChange={(e)=>handleChange(e)}>
                  <option value="">--Please select an category--</option>
                  <option value="live">Live Data</option>
                  <option value="settings">Settings Data</option>
                  <option value="spa_settings">Spa Settings</option>
                  <option value="rfid">RFID Tags</option>
                  <option value="spaboy">SpaBoy Settings</option>
                  <option value="firmware">Firmware</option>
                  <option value="connections">Connections</option>
                  <option value="errors">Errors</option>
                  <option value="statuses">Statuses</option>
                  <option value="upgrade_requests">Upgrade Requests</option>
                  <option value="command_history">Command History</option>
                </select>
              </div>


              {category==="live" &&
                <div className="checkbox-group"> 
                    {liveOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="settings" &&
                <div className="checkbox-group"> 
                    {settingsOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="firmware" &&
                <div className="checkbox-group"> 
                    {firmwareOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="rfid" &&
                <div className="checkbox-group"> 
                    {RFIDOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="spa_settings" &&
                <div className="checkbox-group"> 
                    {spaSetOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="spaboy" &&
                <div className="checkbox-group"> 
                    {spaBoyOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="connections" &&
                <div className="checkbox-group"> 
                    {connectionOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="errors" &&
                <div className="checkbox-group"> 
                    {errorOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="statuses" &&
                <div className="checkbox-group"> 
                    {statusOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="upgrade_requests" &&
                <div className="checkbox-group"> 
                    {upgradeRequestsOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              {category==="command_history" &&
                <div className="checkbox-group"> 
                    {commandHistoryOptions.map((opt)=>
                      <label key={opt.id}><input type="checkbox" onChange={()=>handleCheck(opt)}></input>{opt.label}</label>
                    )}
                </div>
              }

              <div className="reports-sticky">

                <div className="date-input">
                    <div className="since-date">
                      <p className="input-label">From </p>
                        <select className="date-range" name="date-range" id="range-select" onChange={(e)=>handleDateChange(e)}>
                          <option value="">Last Week</option>
                          <option value="today">Today</option>
                          <option value="last_month">Last Month</option>
                          <option value="six_months">Six Months</option>
                          <option value="last_year">Last Year</option>
                        </select>
                    </div>
                    <input type="date" value={startInput.toISOString().split("T")[0]??""} onChange={(e)=>setSInput(new Date(e.target.value))} max={endInput.toISOString().split("T")[0]}></input>
                  </div>

                  <div className="date-input">
                    <p className="input-label">Until </p>
                    <input 
                      type="date" 
                      value={endInput.toISOString().split("T")[0]??""} 
                      onChange={(e)=>setEInput(e.target.value===""? new Date() :new Date(e.target.value))} 
                      min={startInput.toISOString().split("T")[0]} 
                      max={new Date().toISOString().split("T")[0]}>
                    </input>
                  </div>

                <div className="filter-btn-group">
                  <button disabled={selection.length===0||category===""||loading} onClick={handleGenerate}>Generate Report</button>
                </div>
              </div>

            </div>
          </div>
          {
            dataToShow==="live" ?
            <ReportsGraph selected={selected} startDate={startDate} endDate={endDate} spa_guid={spa?.id}/>
            :
            dataToShow==="upgrade_requests" ?
              <ReportUpgradeHistory spa_guid={spa?.id} startDate={startDate} endDate={endDate}/>
            :
            dataToShow==="command_history" ?
              <SpaCommandsTable spa_guid={spa?.id} startDate={startDate} endDate={endDate}/>
            :
            dataToShow===""?
              <div className="empty-table">{`Select Data to generate report.`}</div>
            :
            <ReportsTable selected={selected} startDate={startDate} endDate={endDate} spa_guid={spa?.id} category={dataToShow}/>
          }
        </div>
      </div>
    </>
  );
};

export default SpaReport;
