import "./SpaDetailsPage.css"
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate} from 'react-router-dom';
import {AppDispatch} from "../../redux/store";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import UserCard from "./components/UserCard";
import SpaCard from "./components/SpaCard";
import DetailsGrid from "./components/DetailsGrid";
import {EditIcon, ControlIcon, ReportIcons, ErrorsIcon} from "../../assets/svg/svg";
import {fetchSpa} from "../../redux/actions/spa.actions";
import useMQTT from "../../hooks/useMQTT";
import NetworkCard from "./components/NetworkCard";
import {
    Button,
    CircularProgress,
    Fade,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import RegisterUserModal from "./components/RegisterUserModal";
import EditSerialModal from "./components/EditSerialModal";
import NotFound from "../General/NotFound";
import {SpaErrorLabels} from "../../mqttData/errors/spaErrorLabels.enums";
import {SpaStatusLabels} from "../../mqttData/status/spaStatusLabels.enums";
import {formatErrorCode, formatStatusCode} from "./formatCodes";
import ConfirmFirmwareModal from "../../components/common/Modals/ConfirmFirmwareModal";
import SingleUpdateModal from "../../components/common/Modals/SingleUpdateModal";
import { fetchAvailableFirmware } from "../../redux/actions/firmware.actions";
import Command from "../../classes/command.class";
import { ArcticLabels } from "../SpaErrors/components/arcticLabels.enums";
import roleCheck from "../../components/Layout/roleCheck";
import { commandKeys } from "../../mqttData/commands/commands.enum";

const SpaDetailsPage: React.FC = () => {
    // Data
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const {data: spa, loading: isLoading, isConnected, error} = useSelector((state: RootState) => state.spa);
    const {accessToken} = useSelector((state: RootState) => state.user);
    const [editMode, setEdit] = useState(false);
    const [newSettings, setNewSettings] = useState({});

    // Set modals
    const [openUserRegister, setOpenUserRegister] = useState(false);
    const [openEditSerial, setOpenEditSerial] = useState(false);
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const [singleUpdateOpen, setSingleUpdateOpen] = useState(false);

    const [firmware, setFirmware] = useState("");
    const [version, setVersion] = useState("");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };  

    useEffect(() => {
        if (id) {
            dispatch(fetchSpa(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (accessToken) {
            dispatch(fetchAvailableFirmware(accessToken));
        }
    }, [dispatch, accessToken]);

    // Spa data and settings:
    const liveData = useSelector((state: RootState) => state.live.data);
    const errorsData = useSelector((state: RootState) => state.error.data);
    const statusData = useSelector((state: RootState) => state.status.data);
    const updateData = useSelector((state: RootState) => state.updateStatus.data);
    const {data:user} = useSelector((state: RootState) => state.user);

    // MQTT Functionality:
    const [commandTopic] = useMQTT(spa.id ?? "");
    const command = new Command(commandTopic);

    if (error) {
        return <NotFound/>
    }

    // Update firmware information:
    const getUpdateInfo = () => {
        if (updateData.spa_id === undefined || updateData.spa_id !== id) {
            return "No Data Available";
        }
        return `${updateData.type} version: ${updateData.version} | Progress: ${updateData.progress}%`;
    }

    const shouldShowProgress = () => {
        return updateData.spa_id !== undefined && updateData.spa_id === id;
    }

    const handleCancel = () => {
        setNewSettings({});
        setEdit(false);
      }
  
      const handleSave = () => {
        command.updateSettings({
          ...newSettings,
        });
  
        setNewSettings({});
        setEdit(false);
      }

    const handleReset = (value:number) => {
        command.executeSingle(commandKeys.Reset, value);
    }

    return (
        <>
            <Header/>
            <ConfirmFirmwareModal 
                isOpen={confirmUpdate} 
                setOpen={setConfirmUpdate} 
                setOpenSingle={setSingleUpdateOpen}
                setFirmware={setFirmware}
                setVersion={setVersion}
                spas={[spa]}
            />

            <SingleUpdateModal
                isOpen={singleUpdateOpen} 
                setOpen={setSingleUpdateOpen} 
                id={spa?.id} 
                firmwareType={firmware} 
                version={version} />

            <RegisterUserModal 
                isOpen={openUserRegister} 
                setOpen={setOpenUserRegister} 
                user={spa?.user} 
                spa={spa}
            />

            <EditSerialModal 
                isOpen={openEditSerial} 
                setOpen={setOpenEditSerial} 
                spa={spa}
                spasn={spa.arctic_serial_number as number}
            />

            {isLoading ? (
                <CircularProgress size={"24px"} sx={{position: 'absolute', top: '50%', right: '50%'}}/>
            ) : (
                <div className="details-container">
                    <div className="details-header">
                        <div className="corner-icons">
                            <div className="clickable-icon" onClick={() => navigate(`/spa-control/${spa.id}`)}>
                                <ControlIcon/>
                            </div>
                            <div className="clickable-icon" onClick={() => navigate(`/spa/${spa.id}/report`)}>
                                <ReportIcons/>
                            </div>

                            {roleCheck(user,['admin','developer']) && <div>
                                <Button
                                    id="fade-button"
                                    aria-controls={open ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{color:'var(--font-red)'}}
                                >
                                    Reset
                                </Button>
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    TransitionComponent={Fade}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={()=>handleReset(0)}>All</MenuItem>
                                    <MenuItem onClick={()=>handleReset(1)}>Yocto</MenuItem>
                                    <MenuItem onClick={()=>handleReset(2)}>LPC</MenuItem>
                                    <MenuItem onClick={()=>handleReset(3)}>SpaBoy</MenuItem>
                                </Menu>
                            </div>}
                        </div>
                        <UserCard user={spa?.user} setOpen={setOpenUserRegister}/>

                        <div className="spa-basic-info">
                            <div className="info">
                                <div>{spa?.nick_name ?? `n/a`}</div>
                                {isConnected ?
                                    (
                                        <span className="status-indicator online"></span>
                                    ) : (
                                        <span className="status-indicator offline"></span>
                                    )
                                }
                            </div>


                            <div className="info">
                                <div><strong>SN: </strong>{spa.arctic_serial_number ?? "N/A"}</div>
                                <div className="clickable-icon" onClick={() => setOpenEditSerial(true)}><EditIcon/>
                                </div>
                            </div>

                            <div className="info">
                                <div><strong>Spa
                                    Time: </strong>{liveData.timestamp ? new Date(liveData.timestamp).toUTCString() : '---'}
                                </div>
                            </div>

                            <div className="info">
                                <div className="firmware-update">
                                    <strong>Firmware update progress:</strong>

                                    <div style={{textTransform: "capitalize", whiteSpace: "nowrap"}}>
                                        {getUpdateInfo()}
                                    </div>

                                    {shouldShowProgress() && (
                                        <div className="progress-container">
                                            <div
                                                className="progress-bar"
                                                style={{width: `${updateData.progress ?? 0}%`}}
                                            ></div>
                                            <span className="progress-text">{updateData.progress}%</span>
                                        </div>
                                    )}

                                    <div><button className="button" disabled={shouldShowProgress() && updateData.progress<100} onClick={()=>setConfirmUpdate(true)}>Update Firmware</button></div>
                                </div>
                            </div>
                            
                            <div className="info">
                                <Tooltip title={
                                    Object.values(errorsData).includes(true) || Object.values(statusData).includes(true) ?
                                        <div>
                                            {Object.values(SpaErrorLabels).map((code, i) =>
                                                errorsData[SpaErrorLabels[code]] && ArcticLabels[code]!=="" &&
                                                <div key={i}>{formatErrorCode(code)}</div>
                                            )}
                                            {Object.values(SpaStatusLabels).map((code, i) =>
                                                statusData[SpaStatusLabels[code]] && ArcticLabels[code]!=="" &&
                                                <div key={i}>{formatStatusCode(code)}</div>
                                            )}
                                        </div>
                                        :
                                        <div>No Errors Found.</div>
                                }>
                                    <div className="clickable-icon" style={{display: 'flex', gap: '0.25rem'}}
                                         onClick={() => navigate(`/spa/${spa.id}/errors`)}>
                                        <ErrorsIcon hasErrors={Object.values(SpaErrorLabels).map((code) => {return errorsData[SpaErrorLabels[code]] && ArcticLabels[code]!==""}).includes(true)}
                                                    hasStatus={Object.values(SpaStatusLabels).map((code) => {return statusData[SpaStatusLabels[code]] && ArcticLabels[code]!==""}).includes(true)}/>
                                        <div>More Info..</div>
                                    </div>
                                </Tooltip>
                            </div>

                        </div>
                    </div>

                    {editMode?
                    <span className="edit-group">
                        <button className="edit-button" disabled={Object.keys(newSettings).length===0} onClick={handleSave}><b>Save</b></button>
                        <button className="edit-button" onClick={handleCancel}><b>Cancel</b></button>
                    </span>
                    :
                    <span className="edit-group">
                        <button className="edit-button" disabled={!isConnected} onClick={()=>setEdit(true)}><b>Edit</b></button>
                    </span>
                    }
                    <SpaCard editMode={editMode} newSettings={newSettings} setNewSettings={setNewSettings} />
                    <DetailsGrid editMode={editMode} newSettings={newSettings} setNewSettings={setNewSettings}/>
                    <NetworkCard/>
                </div>
            )}
        </>
    );
};

export default SpaDetailsPage;
