import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select
} from "@mui/material";
import CommentsTable from "./CommentsTable";
import { useParams, useSearchParams } from "react-router-dom";

interface ContainerProps {
  setAddOpen: any;
  setViewOpen: any;
  setDeleteOpen: any;
  setComment: any;
}

const CommentsContainer: React.FC<ContainerProps> = ({
  setAddOpen,
  setViewOpen,
  setDeleteOpen,
  setComment,
}) => {
  //Filters
  const [title, setTitle] = useState(``);
  const [category, setCategory] = useState(``);
  const [unread, setUnread] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {id} = useParams();
  
  useEffect(() => {
    setTitle(searchParams.get('title')??'')
  }, [searchParams]);

  const handleFilter = () => {
    searchParams.delete('page'); //reset page
    title==="" ? searchParams.delete('title') : searchParams.set('title', title);
    category==="" ? searchParams.delete('category') : searchParams.set('category', category);
    !unread ? searchParams.delete('unread') : searchParams.set('unread', 'true');
    setSearchParams(searchParams);
  }

  const handleClear = () => {
    searchParams.delete('page'); //reset page
    setTitle("");
    searchParams.delete('title');

    setCategory("");
    searchParams.delete('category');

    setUnread(false);
    searchParams.delete('unread');

    setSearchParams(searchParams);
  }

  return (
    <div className="container">
        <div className="list">
          <div className="filters">
            <div className="search">
              <TextField
                id="title-search"
                label="Search Title..."
                type="search"
                fullWidth={true}
                value={title??""}
                onChange={(e)=>setTitle(e.target.value)}
                size="small"
              />
              <FormControl fullWidth size="small">
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  value={category}
                  onChange={e => setCategory(e.target.value as string)}
                  defaultValue=""
                >
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>

              <div className="checkbox-filters">
                <label><input type="checkbox" checked={unread} onChange={(e)=>setUnread(e.target.checked)}></input>Unread Comments</label>
              </div>

              <div className="filter-btn-group">
                <button onClick={handleFilter}>Apply Filters</button>
                <button onClick={handleClear}>Clear</button>
              </div>
            </div></div>

          <div className="table-section">  
            <CommentsTable 
              setComment={setComment}
              setViewOpen={setViewOpen}
              setDeleteOpen={setDeleteOpen}
            />
            <div className="filter-btn-group spa-actions">
              {id&&<button onClick={()=>setAddOpen(true)}>Add Comment</button>}
            </div>
          </div>   
        </div>
      </div>      
  );
};

export default CommentsContainer;
