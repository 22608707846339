import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import "./UserSelect.css"
import React, {useEffect, useState} from "react";
import RootState from "../../../redux/states/root.state";
import { fetchCommands } from "../../../redux/actions/commands.actions";
import { CircularProgress, Pagination, Box } from "@mui/material";

interface SelectProps {
    setSelectedCommands: any;
}

const CommandSelect: React.FC<SelectProps> = ({ setSelectedCommands }) => {
const dispatch: AppDispatch = useDispatch();
const {data:commands, meta, loading} = useSelector((state: RootState) => state.commands);
const [pageNumber, setPageNumber] = useState(1);
const [title, setTitle] = useState(``);
const [query, setQuery] = useState<any>(null);

useEffect(() => {
    let param = `?page=${pageNumber}`;
    if (query){
        param = query + `&title=${query}`
    }
    dispatch(fetchCommands(param))
  }, [dispatch,pageNumber, query]);

  return (
    <>
    <div className="user-search-container">
        <input type="search" placeholder="Search title..." onChange={(e)=>setTitle(e.target.value)} disabled={commands.length===0}/>
        <button type="button" className="button" onClick={()=>setQuery(title)} disabled={commands.length===0}>Search</button>
    </div>

    {loading ?
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </Box>
        :
        commands.length>0 ? 
            <div className="select-container">
                <div className="user-select">
                {commands.map((command:any)=>(
                    <div className="command-row" key={command.id}>
                        <input type="radio" name="command-select" onChange={()=>setSelectedCommands(command)}/>
                        <p>{command?.id}</p>
                        <p>{command?.title}</p>
                    </div>
                ))}
                </div>
                {meta?.last_page>1 && <Pagination 
                    count={meta?.last_page} 
                    size="small"
                    onChange={(e,page)=>setPageNumber(page)}
                    page={pageNumber}
                    showLastButton 
                    showFirstButton 
                    disabled={meta?.last_page===1}
                />}
            </div>
        :
        <div>No Commands Found.</div>
        }
    </>
  );
};

export default CommandSelect;
