import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SpasState from '../states/spas.state';
import { fetchSpas } from '../actions/spas.actions';
import { deleteSpa } from '../actions/spa.actions';

const initialState: SpasState = {
  loading: false,
  data: [],
  error: null,
  meta: null,
};

const hotTubSlice = createSlice({
  name: 'hotTub',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpas.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSpas.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSpas.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload ?? null;
      })
      .addCase(deleteSpa.pending, (state) => {
          state.loading = true;
          state.error = null;
      })
      .addCase(deleteSpa.fulfilled, (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.data = action.payload.data;
          state.meta = action.payload.meta;
      })
      .addCase(deleteSpa.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload ?? null;
      });
  },
});

export default hotTubSlice.reducer;