import "./DealershipDetailsPage.css"
import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import RootState from "../../redux/states/root.state";
import Header from "../../components/common/Header/Header";
import { AppDispatch } from "../../redux/store";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchDealership, fetchStaff } from "../../redux/actions/dealership.actions";
import NotFound from "../General/NotFound";
import { CircularProgress } from "@mui/material";
import SimpleUsersTable from "../../components/common/Tables/SimpleUsersTable";
import { EditIcon, LinkOut } from "../../assets/svg/svg";
import EditDealershipModal from "./components/EditDealershipModal";
import roleCheck from "../../components/Layout/roleCheck";

const DealershipDetailsPage: React.FC = () => {
  // Data
  const {id} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const searchParams = useSearchParams()[0];
  // Checked items
  const [checked, setChecked] = useState<any[]>([]);
  const [isCheckAll, setCheckAll] = useState(false);
  const [open, setOpen] = useState(false);
  
  // Data
  const {data: dealership, error, loading:isLoading} = useSelector((state: RootState) => state.dealership);
  const {data:user} = useSelector((state: RootState) => state.user);

  const canEditDealership = roleCheck(user,["admin","developer"]) || (roleCheck(user,["dealer"]) && user?.dealership_id===id) || false;

  useEffect(() => {
    if (id){
      dispatch(fetchDealership(id));
      dispatch(fetchStaff({id:id, params: searchParams.toString()}));
    }
  }, [dispatch,id,searchParams]);

  if (error){
    return <NotFound/>
  }
  
  return (
    <>
    <EditDealershipModal dealership={dealership} isOpen={open} setOpen={setOpen}/>
    <Header/>
    <div className="container">
    {isLoading ? 
        <CircularProgress size={"24px"} sx={{position:'absolute', top:'50%', right:'50%'}}/>
      :
        <div className="list">
          <div className="filters user">
            <div className="card-container details">
              
              <div className="card-title">
                <strong>{dealership?.name}</strong>
                {canEditDealership && <div className="clickable-icon" onClick={()=>setOpen(true)}><EditIcon/></div>}
              </div>
              <hr></hr>
              <div><strong>City: </strong>{dealership?.city??`N/A`}</div>
              <div><strong>Region: </strong>{dealership?.region?.label??`N/A`}</div>
              <div><strong>Country: </strong>{dealership?.country?.label??`N/A`}</div>

            </div>

            <div className="card-container details">
              <div className="card-title">
                <strong>Contact</strong>
              </div>
              <hr></hr>
              <div><strong>Phone: </strong>{dealership?.phone ?? 'N/A'}</div>
              <div><strong>Email: </strong>{dealership?.email ?? 'N/A'}</div>
              <div className="links"><strong>Website: </strong>                      
              {dealership?.website_url?
                <a className="clickable-icon" href={dealership?.website_url} target="_blank" rel="noreferrer">
                  <LinkOut/>
                </a>
                :
                <></>
              }</div>
              <div className="links"><strong>Supplies: </strong>
              {dealership?.supplies_url?
                <a className="clickable-icon" href={dealership?.supplies_url} target="_blank" rel="noreferrer">
                  <LinkOut/>
                </a>
                :
                <></>
              }</div>
            </div>
          </div>

          <div className="table-section">
            <SimpleUsersTable
              checked={checked}
              setChecked={setChecked}
              isCheckAll={isCheckAll}
              setCheckAll={setCheckAll}
              page="dealership"
            />
            <div className="filter-btn-group spa-actions"></div>
          </div>   

        </div>}
      </div>     
    </>
  );
};

export default DealershipDetailsPage;
