import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SpaState from '../states/spa.state';
import { deregisterSpaUser, editNickname, fetchSpa, registerSpaUser } from '../actions/spa.actions';
import { addSpaCommands } from '../actions/commands.actions';

const initialState: SpaState = {
    loading: false,
    isConnected: false,
    data: [],
    error: null,
};

const spaSlice = createSlice({
    name: 'spa',
    initialState,
    reducers: {
        setConnected: (state) => {
            state.isConnected = true;
        },
        setDisconnected: (state) => {
            state.isConnected = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpa.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSpa.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchSpa.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(editNickname.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(editNickname.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(editNickname.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(registerSpaUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(registerSpaUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(registerSpaUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })

            .addCase(deregisterSpaUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deregisterSpaUser.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(deregisterSpaUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            })
            
            .addCase(addSpaCommands.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(addSpaCommands.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(addSpaCommands.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload ?? null;
            });
    },
});

export const { setConnected, setDisconnected } = spaSlice.actions;
export default spaSlice.reducer;