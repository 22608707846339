import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDealership, getDealerships, getStaff, putDealership } from '../../services/api/dealerships.api';
import RootState from "../states/root.state";

export const fetchDealerships = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership/list',
  async (params, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getDealerships(token,params);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const fetchDealership = createAsyncThunk<any, string, { rejectValue: string; state: RootState}>(
  'dealership',
  async (id, {rejectWithValue, getState}) => {

    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getDealership(token, id);
      return response.data;
    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const fetchStaff = createAsyncThunk<any, {id:string, params:string}, { rejectValue: string; state: RootState}>(
  'dealership/staff',
  async (getInfo, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await getStaff(token, getInfo);
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    } 
  }
);

export const updateDealership = createAsyncThunk<
  any, 
{
  id:string;
  name:string;
  city:string;
  phone:string;
  email:string;
  website_url:string;
  supplies_url:string;
  region_id:string;
  country_id:string;
},
{ rejectValue: string; state: RootState}>(
  'dealership/update',
  async (dealership, {rejectWithValue, getState}) => {
    try {
      const {user} = getState();
      const token = user.accessToken;

      const response = await putDealership(token, dealership).then(()=>getDealership(token,dealership.id));
      return response.data;

    } catch (error: any) {
        if (error.response) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue('An error occurred during updating.');
        }
    }
  }
);